import axios from "axios";

const LOCAL_STORAGE_KEYS = {
  accessToken: "spotify_access_token",
  refreshToken: "refresh_token",
  expireTime: "spotify_expire_time",
  timestamp: "spotify_timestamp",
};

const LOCAL_STORAGE_VALUES = {
  accessToken: window.localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken),
  refreshToken: window.localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken),
  expireTime: window.localStorage.getItem(LOCAL_STORAGE_KEYS.expireTime),
  timestamp: window.localStorage.getItem(LOCAL_STORAGE_KEYS.timestamp),
};

export const logout = () => {
  for (const key in LOCAL_STORAGE_KEYS)
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS[key]);

  window.location = window.location.origin;
};

const hashTokenExpired = () => {
  const { accessToken, timestamp, expireTime } = LOCAL_STORAGE_VALUES;

  if (!accessToken || !timestamp) return false;

  const millisecondsElapsed = Date.now() - Number(timestamp);
  return millisecondsElapsed / 1000 > Number(expireTime);
};

const refreshToken = async () => {
  try {
    if (
      !LOCAL_STORAGE_VALUES.refreshToken ||
      LOCAL_STORAGE_VALUES.refreshToken === "undefined" ||
      Date.now() - Number(LOCAL_STORAGE_VALUES.timestamp) / 1000 < 1000
    ) {
      console.error("no refresh token available!");
      logout();
    }

    const { data } = await axios.get(
      `refresh_token?refresh_token=${LOCAL_STORAGE_VALUES.refreshToken}`
    );

    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.accessToken,
      data.access_token
    );
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.timestamp, Date.now());
    window.location.reload();
  } catch (e) {
    console.error(e);
  }
};

const getAccessToken = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const queryParams = {
    [LOCAL_STORAGE_KEYS.accessToken]: urlParams.get("access_token"),
    [LOCAL_STORAGE_KEYS.refreshToken]: urlParams.get("refresh_token"),
    [LOCAL_STORAGE_KEYS.expireTime]: urlParams.get("expires_in"),
  };

  const hasError = urlParams.get("error");

  // refresh token if it is expired or there's an error
  if (
    hasError ||
    hashTokenExpired() ||
    LOCAL_STORAGE_VALUES.accessToken === "undefined"
  )
    refreshToken();

  // return valid token stored locally
  if (
    LOCAL_STORAGE_VALUES.accessToken &&
    LOCAL_STORAGE_VALUES.accessToken !== "undefined"
  )
    return LOCAL_STORAGE_VALUES.accessToken;

  // first time logging in
  if (queryParams[LOCAL_STORAGE_KEYS.accessToken]) {
    for (const param in queryParams) {
      window.localStorage.setItem(param, queryParams[param]);
    }

    window.localStorage.setItem(LOCAL_STORAGE_KEYS.timestamp, Date.now());

    return queryParams[LOCAL_STORAGE_KEYS.accessToken];
  }
  return false;
};

export const accessToken = getAccessToken();

axios.defaults.baseURL = "https://api.spotify.com/v1";
axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
axios.defaults.headers["Content-type"] = "application/json";

export const getTopTracks = async (time_range) =>
  axios.get(`me/top/tracks?time_range=${time_range}&limit=50`);

export const getTopArtists = (time_range) =>
  axios.get(`me/top/artists?time_range=${time_range}&limit=50`);

export const getUser = async () => axios.get("/me");

export const createPlaylist = async (user_id, name) =>
  axios.post(`users/${user_id}/playlists`, {
    name: name,
    description: "generated by knowyourtaste.",
  });

export const addTracks = async (playlist_id, uris) =>
  axios.post(`playlists/${playlist_id}/tracks`, {
    uris: uris,
  });
